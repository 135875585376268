import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import CrApp from './CrApp';
import VideoPlayer from './videoPlayer';
import SignInSide from './SignInSide';
import SignUp from './SignUp';
import PwResetSide from './pwReset';
import { Authenticator } from '@aws-amplify/ui-react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Authenticator.Provider>
    <React.StrictMode>
      <Router>
        <Routes>
          <Route exact path="/" element={<SignInSide />}/>
          <Route exact path="/pwReset" element={<PwResetSide />} />
          <Route exact path="/SignUp" element={<SignUp />}/>
          <Route exact path="/home" element={ <CrApp />} />
          <Route path="/:name" element={<VideoPlayer />} />
        </Routes>
      </Router>
    </React.StrictMode>
  </Authenticator.Provider>
);

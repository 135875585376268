import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Unstable_Grid2';
import { CardActionArea } from '@mui/material';
import './CrApp.css';


export default function Contentcard({image, title, videoURL}) {
    const [height, setHeight] = React.useState(window.innerHeight);

    React.useEffect(() => {
      const handleResize = () => {
        setHeight(window.innerHeight);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    let imageHeight = height / 2;


    return (
        <Grid xs={6} sm={4} md={3}>
            <CardActionArea href={"/"+videoURL}>
                <Card 
                    sx={{ maxWidth: 345 }}
                    className="content-card">
                    <CardMedia
                        sx={{ height: imageHeight }}
                        image={image}
                    />
                </Card>
            </CardActionArea>
        </Grid>
    );
}
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material';
import { signOut } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';

export default function NavBar() {
    Amplify.configure(config);
    async function handleSignOut() {
        try {
          await signOut({ global: true });
          localStorage.clear();
        } catch (error) {
          console.log('error signing out: ', error);
        }
      }

    const theme = createTheme({
        palette: {
            primary: {
                main: '#607d8b',
            },
            secondary: {
                main: '#455a64',
            }
        },
    });

  return (
    <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1, paddingBottom: '1.5vh'}}>
        <AppBar position="static">
            <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Stream It
            </Typography>
            <Button href= "/home" color="inherit">Home</Button>
            <Button href="/" onClick={handleSignOut} color="inherit">Sign Out</Button>
            </Toolbar>
        </AppBar>
        </Box>
    </ThemeProvider>
  );
}